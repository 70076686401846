import styled from 'styled-components';
import { Selected } from '@adsk/alloy-react-dropdown';

export const InputsHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const InputsFilterRenderItem = styled(Selected)`
  display: flex;
  align-items: center;
`;

export const InputsFilterDropDown = styled.div`
  margin-left: auto;
  padding-right: ${({ theme }) => `${theme.vars.paddingBase}px`};
`;
