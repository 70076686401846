import React, { useState } from 'react';
import { LeftSection } from '../styles';
import { Button, ICON_TYPES } from '@adsk/alloy-react';
import text from '../../../../Common/global/text/text.json';
import { LeftButton } from '../../../../Common/global/styles';

const SourceContent: React.FC<{
  handleNewTemplateClick: () => void;
  handleOpenSavedDraftsClick: () => void;
}> = ({ handleNewTemplateClick, handleOpenSavedDraftsClick }): JSX.Element => {
  // Hardcoded drafts quantity. Should get it from global context later
  const [draftsQuantity, _setDraftsQuantity] = useState(4);

  return (
    <>
      <LeftSection>
        <LeftButton
          icon={ICON_TYPES.PLUS_CIRCLE_FILLED}
          onClick={handleNewTemplateClick}
          size={Button.SIZES.SMALL}
        >
          {text.buttonNewTemplate}
        </LeftButton>
        <LeftButton
          icon={ICON_TYPES.BOOKMARK_FILLED}
          onClick={handleOpenSavedDraftsClick}
          size={Button.SIZES.SMALL}
        >
          {text.buttonSavedDrafts} ({draftsQuantity})
        </LeftButton>
      </LeftSection>
    </>
  );
};

export default SourceContent;
