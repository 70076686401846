import styled from 'styled-components';

export const DropdownTitle = styled.h4`
  ${({ theme }) => theme.typography.bodyMedium}
  padding: 0;
  margin: 0;
`;

export const DropdownComponent = styled.div<{ width?: number }>`
  width: ${({ width, theme }) => width || theme.vars.wideDropDown}px;
  padding: 0;
  margin: 0 0 0 ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const DropdownIconContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  margin: 0px ${({ theme }) => `${theme.vars.marginBase}px`} 0px 0px;
`;
