import React from 'react';
import { SourceContentRightButton, SourceContentTextInput } from '../styles';
import text from '../../../../../Common/global/text/text.json';
import { MIDFormField } from '../../../../../Common/global/styles';
import { Button } from '@adsk/alloy-react';
import { mockFetchIPJService } from './mockServices';

interface IPJSectionProps {
  iPJ: string;
  setIPJ: React.Dispatch<React.SetStateAction<string>>;
}

const IPJSection: React.FC<IPJSectionProps> = ({ iPJ, setIPJ }): JSX.Element => {
  const handleSelectOrReplaceClick = async () => {
    // It will call a service that fetches IPJ
    const { inventorProject } = await mockFetchIPJService();
    setIPJ(inventorProject);
  };

  return (
    <MIDFormField label={text.sourceContentIPJLabel} labelVariant="top">
      <SourceContentTextInput
        data-testid="ipj-input"
        readOnly
        type="text"
        placeholder="Not selected"
        defaultValue={iPJ}
      />
      {iPJ ? (
        <SourceContentRightButton
          data-testid="ipj-replace-button"
          onClick={handleSelectOrReplaceClick}
          size={Button.SIZES.MEDIUM}
        >
          {text.buttonReplace}
        </SourceContentRightButton>
      ) : (
        <SourceContentRightButton
          data-testid="ipj-select-button"
          onClick={handleSelectOrReplaceClick}
          variant={Button.VARIANTS.PRIMARY}
          size={Button.SIZES.MEDIUM}
        >
          {text.buttonSelect}
        </SourceContentRightButton>
      )}
    </MIDFormField>
  );
};

export default IPJSection;
