import React from 'react';
import InputsHeader from './InputsHeader';
import InputsTable from './InputsTable';
import { useInputsHeader } from './useInputsHeader';
import { useInputsTable } from './useInputsTable';
import { filterIProperties, filterParameters } from './utils';
import { iPropertiesTableColumns, parameterTableColumns } from './constants';
import { InputsSelectionProp } from './types';

const InputsSelection: React.FC<InputsSelectionProp> = ({
  selectedParameterIds,
  setSelectedParameterIds,
  selectedIPropertyIds,
  setSelectedIPropertyIds,
  inventorData,
  totalSelected,
  setIsEditStep,
}): JSX.Element => {
  const {
    buttonGroupValue,
    handleOnParameterOriPropertyButtonChange,
    currentFilter,
    setCurrentFilter,
    filterItems,
    isParameter,
    handleOnAddInputsButtonClick,
  } = useInputsHeader(setIsEditStep);

  const parametersTableProps = useInputsTable({
    // Initialize the table data
    initialTableData: inventorData.parameters,
    // The key in each table data used as table rowId
    rowIdKey: 'name',
    // table columns
    tableColumns: parameterTableColumns,
    // Used to help generate handleOnAction: when user toggle the checkbox in table
    setSelectedIds: setSelectedParameterIds,
    // Used by filterParameters function to filter data
    selectedIds: selectedParameterIds,
    // Used by filterParameters function to filter data
    currentFilter,
    // The function used to filter data
    filterFunc: filterParameters,
  });

  const iPropertiesTableProps = useInputsTable({
    // Initialize the table data
    initialTableData: inventorData.iProperties,
    // The key in each table data used as table rowId
    rowIdKey: 'id',
    // table columns
    tableColumns: iPropertiesTableColumns,
    // Used to help generate handleOnAction: when user toggle the checkbox in table
    setSelectedIds: setSelectedIPropertyIds,
    // The function used to filter data
    filterFunc: filterIProperties,
    // Used by filterIProperties function to filter data
    selectedIds: selectedIPropertyIds,
    // Used by filterIProperties function to filter data
    currentFilter,
  });
  return (
    <>
      <InputsHeader
        buttonGroupValue={buttonGroupValue}
        handleOnParameterOriPropertyButtonChange={handleOnParameterOriPropertyButtonChange}
        filterItems={filterItems}
        currentFilter={currentFilter}
        setCurrentFilter={setCurrentFilter}
        totalSelected={totalSelected}
        handleOnAddInputsButtonClick={handleOnAddInputsButtonClick}
      />
      {isParameter ? (
        <InputsTable {...parametersTableProps} />
      ) : (
        <InputsTable {...iPropertiesTableProps} />
      )}
    </>
  );
};

export default InputsSelection;
