import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import Header from './components/Header/Header';
import { ScreenManager } from './components/ScreenRenderer/ScreenRenderer';
import DataContext from './context/Data.context';
import { useStore as useDataStore } from './context/dataStore';
import NavigationContext from './context/Navigation.context';
import { useNavigationStore } from './context/navigationStore';
import midTheme from '../Common/global/midTheme';
import Main from '../Common/components/Main/Main';
import { showDevTools } from '../lib/utils/tools';

const App: React.FC = (): JSX.Element => {
  const dataStore = useDataStore();
  const navigationStore = useNavigationStore();
  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'F12') {
        showDevTools();
      }
    });
  }, []);

  return (
    <ThemeProvider theme={midTheme}>
      <NavigationContext.Provider value={navigationStore}>
        <DataContext.Provider value={dataStore}>
          <Header />
          <Main>
            <ScreenManager />
          </Main>
        </DataContext.Provider>
      </NavigationContext.Provider>
    </ThemeProvider>
  );
};

export default App;
