// TODO: Remove all this file when real services are available

import { MetaInfo } from '../../../../../lib/interfaces/templates';

interface MockFetchTopFolderResponse {
  folder: MetaInfo;
}

interface MockFetchIPJResponse {
  inventorProject: string;
}

interface MockFetchAssemblyResponse {
  assembly: string;
  assemblyPath: string;
}

export const mockFetchTopFolderService = async (): Promise<MockFetchTopFolderResponse> =>
  Promise.resolve({
    folder: { id: '1231231', name: 'AEWalls' },
  });

export const mockFetchIPJService = async (): Promise<MockFetchIPJResponse> =>
  Promise.resolve({
    inventorProject: 'AEWalls.ipj',
  });

export const mockFetchAssemblyService = async (): Promise<MockFetchAssemblyResponse> =>
  Promise.resolve({
    assembly: 'WallWD201.iam',
    assemblyPath: 'path/to/WallWD201.iam',
  });
