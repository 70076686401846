import React from 'react';
import EditInputs from './EditInputs/EditInputsView';
import { useInputsTab } from './useInputsTab';
import InputsSelection from './InputsSelection/InputsSelection';

const InputsTab: React.FC = (): JSX.Element => {
  const {
    isEditStep,
    setIsEditStep,
    selectedParameterIds,
    setSelectedParameterIds,
    selectedIPropertyIds,
    setSelectedIPropertyIds,
    inventorData,
    totalSelected,
    selectedInputs,
  } = useInputsTab();
  return !isEditStep ? (
    <InputsSelection
      selectedParameterIds={selectedParameterIds}
      setSelectedParameterIds={setSelectedParameterIds}
      selectedIPropertyIds={selectedIPropertyIds}
      setSelectedIPropertyIds={setSelectedIPropertyIds}
      inventorData={inventorData}
      totalSelected={totalSelected}
      setIsEditStep={setIsEditStep}
    />
  ) : (
    <EditInputs setIsEditStep={setIsEditStep} selectedInputs={selectedInputs} />
  );
};

export default InputsTab;
