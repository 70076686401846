import {
  IProperty,
  InventorProperties,
  InventorParameter,
} from '../../../../lib/interfaces/templates';

export const mockInputsData: InventorProperties = {
  parameters: [
    {
      name: 'Width',
      parameterType: 'User',
      unitType: 'mm',
      value: '3500',
      isKey: true,
    },
    {
      name: 'Depth',
      parameterType: 'User',
      unitType: 'mm',
      value: '1500',
      isKey: true,
    },
    {
      name: 'd0',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd1',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd5',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd7',
      parameterType: 'Model',
      unitType: 'mm',
      value: '8',
      isKey: false,
    },
    {
      name: 'd8',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd9',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'Spacing',
      parameterType: 'User',
      unitType: 'mm',
      value: '400',
      isKey: false,
    },
    {
      name: 'EqualCheck',
      parameterType: 'User',
      unitType: 'Boolean',
      value: 'True',
      isKey: false,
    },
    {
      name: 'InterX1',
      parameterType: 'User',
      unitType: 'ul',
      value: '4',
      isKey: false,
    },
    {
      name: 'InterX2',
      parameterType: 'User',
      unitType: 'ul',
      value: '5',
      isKey: false,
    },
    {
      name: 'InterXStart',
      parameterType: 'User',
      unitType: 'mm',
      value: '200',
      isKey: false,
    },
    {
      name: 'd15',
      parameterType: 'Model',
      unitType: 'mm',
      value: '100',
      isKey: false,
    },
    {
      name: 'd16',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd17',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd18',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd19',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'InterAdj',
      parameterType: 'User',
      unitType: 'ul',
      value: '1',
      isKey: false,
    },
    {
      name: 'd22',
      parameterType: 'Model',
      unitType: 'mm',
      value: '-105',
      isKey: false,
    },
    {
      name: 'd23',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd25',
      parameterType: 'Model',
      unitType: 'mm',
      value: '-100',
      isKey: false,
    },
    {
      name: 'd27',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd28',
      parameterType: 'Model',
      unitType: 'mm',
      value: '-100',
      isKey: false,
    },
    {
      name: 'd29',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd31',
      parameterType: 'Model',
      unitType: 'mm',
      value: '2',
      isKey: false,
    },
    {
      name: 'd32',
      parameterType: 'Model',
      unitType: 'mm',
      value: '150',
      isKey: false,
    },
    {
      name: 'd33',
      parameterType: 'Model',
      unitType: 'ul',
      value: '1',
      isKey: false,
    },
    {
      name: 'd35',
      parameterType: 'Model',
      unitType: 'mm',
      value: '2',
      isKey: false,
    },
    {
      name: 'd36',
      parameterType: 'Model',
      unitType: 'mm',
      value: '150',
      isKey: false,
    },
    {
      name: 'd37',
      parameterType: 'Model',
      unitType: 'ul',
      value: '1',
      isKey: false,
    },
    {
      name: 'DeckNum',
      parameterType: 'User',
      unitType: 'ul',
      value: '9',
      isKey: false,
    },
    {
      name: 'd39',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd40',
      parameterType: 'Model',
      unitType: 'mm',
      value: '150',
      isKey: false,
    },
    {
      name: 'd41',
      parameterType: 'Model',
      unitType: 'mm',
      value: '-70',
      isKey: false,
    },
    {
      name: 'd42',
      parameterType: 'Model',
      unitType: 'mm',
      value: '-150',
      isKey: false,
    },
    {
      name: 'd43',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd44',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'StdXDist',
      parameterType: 'User',
      unitType: 'mm',
      value: '1200',
      isKey: false,
    },
    {
      name: 'StdXNum',
      parameterType: 'User',
      unitType: 'ul',
      value: '1',
      isKey: false,
    },
    {
      name: 'd45',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd46',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd47',
      parameterType: 'Model',
      unitType: 'mm',
      value: '-150',
      isKey: false,
    },
    {
      name: 'StdXRemainder',
      parameterType: 'User',
      unitType: 'mm',
      value: '1200',
      isKey: false,
    },
    {
      name: 'd48',
      parameterType: 'Model',
      unitType: 'mm',
      value: '2',
      isKey: false,
    },
    {
      name: 'd50',
      parameterType: 'Model',
      unitType: 'ul',
      value: '1',
      isKey: false,
    },
    {
      name: 'd52',
      parameterType: 'Model',
      unitType: 'mm',
      value: '150',
      isKey: false,
    },
    {
      name: 'd53',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd54',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd55',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd56',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd57',
      parameterType: 'Model',
      unitType: 'mm',
      value: '-150',
      isKey: false,
    },
    {
      name: 'd58',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd59',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd60',
      parameterType: 'Model',
      unitType: 'mm',
      value: '150',
      isKey: false,
    },
    {
      name: 'StdYDist',
      parameterType: 'User',
      unitType: 'mm',
      value: '1066.6666666666699',
      isKey: false,
    },
    {
      name: 'StdYNum',
      parameterType: 'User',
      unitType: 'ul',
      value: '3',
      isKey: false,
    },
    {
      name: 'StdYRemainder',
      parameterType: 'User',
      unitType: 'mm',
      value: '3200',
      isKey: false,
    },
    {
      name: 'd61',
      parameterType: 'Model',
      unitType: 'mm',
      value: '2',
      isKey: false,
    },
    {
      name: 'd63',
      parameterType: 'Model',
      unitType: 'ul',
      value: '1',
      isKey: false,
    },
    {
      name: 'd65',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd66',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd67',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd68',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd69',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd70',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd71',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd72',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'GlassXNum',
      parameterType: 'User',
      unitType: 'ul',
      value: '1',
      isKey: false,
    },
    {
      name: 'GlassYNum',
      parameterType: 'User',
      unitType: 'ul',
      value: '3',
      isKey: false,
    },
    {
      name: 'd73',
      parameterType: 'Model',
      unitType: 'mm',
      value: '2',
      isKey: false,
    },
    {
      name: 'd75',
      parameterType: 'Model',
      unitType: 'ul',
      value: '1',
      isKey: false,
    },
    {
      name: 'd77',
      parameterType: 'Model',
      unitType: 'mm',
      value: '2',
      isKey: false,
    },
    {
      name: 'd79',
      parameterType: 'Model',
      unitType: 'ul',
      value: '1',
      isKey: false,
    },
    {
      name: 'd81',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd82',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd83',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd84',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd85',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd86',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd87',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd88',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd89',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd90',
      parameterType: 'Model',
      unitType: 'deg',
      value: '0',
      isKey: false,
    },
    {
      name: 'd91',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd92',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd93',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd94',
      parameterType: 'Model',
      unitType: 'mm',
      value: '-40',
      isKey: false,
    },
    {
      name: 'd95',
      parameterType: 'Model',
      unitType: 'mm',
      value: '-20',
      isKey: false,
    },
    {
      name: 'd96',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
    {
      name: 'd97',
      parameterType: 'Model',
      unitType: 'mm',
      value: '-40',
      isKey: false,
    },
    {
      name: 'd98',
      parameterType: 'Model',
      unitType: 'mm',
      value: '20',
      isKey: false,
    },
    {
      name: 'BIMSimplify',
      parameterType: 'User',
      unitType: 'Boolean',
      value: 'False',
      isKey: false,
    },
    {
      name: 'MyNumeric',
      parameterType: 'User',
      unitType: 'm',
      value: '0.38333333333333336',
      isKey: false,
    },
  ],
  iProperties: [
    {
      id: '5c469615-d03f-8c83-3799-487013a42e7d',
      category: 'Summary',
      displayName: 'Author',
      value: 'alid',
    },
    {
      id: '754093ca-4482-1c46-2c52-a875c478d7cb',
      category: 'Summary',
      displayName: 'Comments',
      value: '',
    },
    {
      id: 'ea0fbee8-45a2-ad87-8afa-482bbceb306a',
      category: 'Summary',
      displayName: 'Keywords',
      value: '',
    },
    {
      id: '77b1bcbd-5e48-5aaf-f3bf-657d285c6df8',
      category: 'Summary',
      displayName: 'Subject',
      value: '',
    },
    {
      id: 'fb6cc9b8-fbf2-60ef-d856-1bc5ec0ea88a',
      category: 'Summary',
      displayName: 'Title',
      value: 'Balcony Sub-Assembly',
    },
    {
      id: '840106a0-b283-2d69-214a-6a01df3b9e84',
      category: 'Summary',
      displayName: 'Category',
      value: '',
    },
    {
      id: '2c539e9a-1a2b-9a5c-dbd3-08f99b4e6efe',
      category: 'Summary',
      displayName: 'Company',
      value: '',
    },
    {
      id: '37b8609d-0d22-727f-7174-dec27322d763',
      category: 'Summary',
      displayName: 'Manager',
      value: 'alid',
    },
    {
      id: 'b6b6c4c6-921f-e514-dbcc-945306b22a79',
      category: 'Project',
      displayName: 'Authority',
      value: '',
    },
    {
      id: '9f8cc95f-5b66-58c9-7ea8-2ef11778f3ba',
      category: 'Project',
      displayName: 'Catalog web link',
      value: 'www.autodesk.co.uk',
    },
    {
      id: 'c3e539e3-9d8b-458f-6d8e-b75cb8d2f409',
      category: 'Project',
      displayName: 'Cost',
      value: '5000',
    },
    {
      id: 'b1b4a34a-2ca3-f542-d30f-6a5f063b1144',
      category: 'Project',
      displayName: 'Cost Center',
      value: '1066',
    },
    {
      id: '18024b55-c9e5-05fd-cced-4b6c141d023c',
      category: 'Project',
      displayName: 'Date Created',
      value: '04/12/2016',
    },
    {
      id: 'abb80390-2561-531e-1c5d-4c7aaac0371a',
      category: 'Project',
      displayName: 'Designer',
      value: 'alid',
    },
    {
      id: '1ff7df8c-6dff-e690-8973-aa58240cf0d6',
      category: 'Project',
      displayName: 'Description',
      value: 'Residential Balcony Assembly',
    },
    {
      id: 'bc40dc93-3690-7a02-2ce5-6e5f24da2a5a',
      category: 'Project',
      displayName: 'Engineer',
      value: 'alid',
    },
    {
      id: '58aeecde-fe80-75ec-d9f2-23b437ae00ab',
      category: 'Project',
      displayName: 'Part Number',
      value: 'AD-0000-1',
    },
    {
      id: '4b5864bc-788a-16df-ec40-2b6503895d7d',
      category: 'Project',
      displayName: 'Project',
      value: 'ADSK',
    },
    {
      id: '8dc5639b-8d0d-ca28-ee6b-3c2982747ae2',
      category: 'Project',
      displayName: 'Stock Number',
      value: '',
    },
    {
      id: '03d481ff-f4ee-d27f-ae14-725450cb04cb',
      category: 'Project',
      displayName: 'Vendor',
      value: 'Design Specific',
    },
    {
      id: '70b99b3c-1276-fd96-9105-47aea1fa4aaf',
      category: 'Physical',
      displayName: 'Material',
      value: '',
    },
    {
      id: '9011bf6d-a523-5530-d1ab-ab29ba939b77',
      category: 'Physical',
      displayName: 'Mass',
      value: '1018382.44012173',
    },
    {
      id: '4a547126-3542-ebf0-1448-b804994f4c3a',
      category: 'User',
      displayName: 'Dimensions',
      value: '3000mmx1500mmx900mm',
    },
  ],
};

export const mockTestInputsData: InventorProperties = {
  parameters: [
    {
      name: 'Width',
      parameterType: 'User',
      unitType: 'mm',
      value: '3500',
      isKey: true,
    },
    {
      name: 'Depth',
      parameterType: 'User',
      unitType: 'mm',
      value: '1500',
      isKey: false,
    },
    {
      name: 'd0',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: true,
    },
    {
      name: 'd1',
      parameterType: 'Model',
      unitType: 'mm',
      value: '0',
      isKey: false,
    },
  ],
  iProperties: [
    {
      id: 'fb6cc9b8-fbf2-60ef-d856-1bc5ec0ea88a',
      category: 'Summary',
      displayName: 'Title',
      value: 'Balcony Sub-Assembly',
    },
    {
      id: '77b1bcbd-5e48-5aaf-f3bf-657d285c6df8',
      category: 'Summary',
      displayName: 'Subject',
      value: '',
    },
    {
      id: '5c469615-d03f-8c83-3799-487013a42e7d',
      category: 'Summary',
      displayName: 'Author',
      value: 'alid',
    },
  ],
};

export const mockTestInputsParametersFilteredByAll: InventorParameter[] =
  mockTestInputsData.parameters;

export const mockTestInputsIPropertiesFilteredByAll: IProperty[] = mockTestInputsData.iProperties;

export const mockTestInputsParametersFilteredByUser: InventorParameter[] = [
  {
    name: 'Width',
    parameterType: 'User',
    unitType: 'mm',
    value: '3500',
    isKey: true,
  },
  {
    name: 'Depth',
    parameterType: 'User',
    unitType: 'mm',
    value: '1500',
    isKey: false,
  },
];

export const mockTestInputsParametersFilteredByModel: InventorParameter[] = [
  {
    name: 'd0',
    parameterType: 'Model',
    unitType: 'mm',
    value: '0',
    isKey: true,
  },
  {
    name: 'd1',
    parameterType: 'Model',
    unitType: 'mm',
    value: '0',
    isKey: false,
  },
];

export const mockTestInputsParametersFilteredByKey: InventorParameter[] = [
  {
    name: 'Width',
    parameterType: 'User',
    unitType: 'mm',
    value: '3500',
    isKey: true,
  },
  {
    name: 'd0',
    parameterType: 'Model',
    unitType: 'mm',
    value: '0',
    isKey: true,
  },
];

export const mockTestInputsParametersFilteredBySelected: InventorParameter[] = [
  {
    name: 'Width',
    parameterType: 'User',
    unitType: 'mm',
    value: '3500',
    isKey: true,
  },
];

export const mockTestInputsIPropertiesFilteredByWithValue: IProperty[] = [
  {
    id: 'fb6cc9b8-fbf2-60ef-d856-1bc5ec0ea88a',
    category: 'Summary',
    displayName: 'Title',
    value: 'Balcony Sub-Assembly',
  },
  {
    id: '5c469615-d03f-8c83-3799-487013a42e7d',
    category: 'Summary',
    displayName: 'Author',
    value: 'alid',
  },
];

export const mockTestInputsIPropertiesFilteredBySelected: IProperty[] = [
  {
    id: 'fb6cc9b8-fbf2-60ef-d856-1bc5ec0ea88a',
    category: 'Summary',
    displayName: 'Title',
    value: 'Balcony Sub-Assembly',
  },
];
