import React, { useState } from 'react';
import { LeftSection, RightSection, Subtitle } from '../styles';
import text from '../../../../Common/global/text/text.json';
import { Button } from '@adsk/alloy-react';
import { RightButton } from '../../../../Common/global/styles';

const SelectDataset: React.FC<{ handleOpenSavedDraftsClick: () => void }> = ({
  handleOpenSavedDraftsClick,
}): JSX.Element => {
  // Hardcoded drafts quantity. Should get it from global context later
  const [draftsQuantity, _setDraftsQuantity] = useState(4);
  return (
    <>
      <LeftSection>
        <Subtitle>{text.subHeaderTitleSelectDataset}</Subtitle>
      </LeftSection>
      <RightSection>
        <RightButton onClick={handleOpenSavedDraftsClick} size={Button.SIZES.SMALL}>
          {`${text.buttonSavedDrafts} (${draftsQuantity})`}
        </RightButton>
      </RightSection>
    </>
  );
};

export default SelectDataset;
