import React from 'react';
import {
  SingleSelectMenu,
  useDropdown,
  Control,
  MenuItem,
  Selected,
} from '@adsk/alloy-react-dropdown';
import { DropdownComponent, DropdownIconContainer, DropdownTitle } from './styles';
import { DropdownItem, DropdownProps } from './types';
import { Icon } from '@adsk/alloy-react';

export const Dropdown: React.FC<DropdownProps> = ({
  elements,
  title,
  menuItemIcon,
  controlIcon,
  onSelect,
  disabled,
  selectedItem,
  width,
}): JSX.Element => {
  const {
    isOpen,
    menuItems,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
    targetProps,
    overlayProps,
  } = useDropdown({ items: elements, onSelectedItemChange: onSelect, selectedItem });

  const renderItem = (controlIcon?: string) => (item: { selectedItem?: DropdownItem | null }) =>
    (
      <DropdownIconContainer>
        {controlIcon && <Icon type={controlIcon as any} fr={0} />}
        {item.selectedItem && <Selected>{item.selectedItem?.value}</Selected>}
      </DropdownIconContainer>
    );
  const renderMenuItemLeftComponent = () => (
    <DropdownIconContainer>
      {menuItemIcon && <Icon fr={0} type={menuItemIcon as any} />}
    </DropdownIconContainer>
  );
  const renderMenuItem = (props: any) => (
    <MenuItem {...props} renderLeftComponent={renderMenuItemLeftComponent} />
  );
  return (
    <DropdownComponent width={width}>
      {title && <DropdownTitle>{title}</DropdownTitle>}
      <Control
        isOpen={isOpen}
        selectedItem={selectedItem || null}
        getToggleButtonProps={getToggleButtonProps}
        getLabelProps={getLabelProps}
        targetProps={targetProps}
        isDisabled={disabled}
        renderItem={renderItem(controlIcon)}
      />
      <SingleSelectMenu
        isOpen={isOpen}
        overlayProps={overlayProps}
        menuItems={menuItems}
        targetProps={targetProps}
        highlightedIndex={highlightedIndex}
        selectedItem={selectedItem || null}
        getMenuProps={getMenuProps}
        getItemProps={getItemProps}
        renderMenuItem={renderMenuItem}
      />
    </DropdownComponent>
  );
};
