import { Button, ICON_TYPES } from '@adsk/alloy-react';
import React, { useContext } from 'react';
import { HeaderWrapper, LeftSection, RightSection, SubHeader, Title, TopHeader } from './styles';
import Initial from './SubHeaders/Initial';
import SourceContent from './SubHeaders/SourceContent';
import text from '../../../Common/global/text/text.json';
import { Screens } from '../../context/navigationStore';
import NavigationContext from '../../context/Navigation.context';
import DataContext from '../../context/Data.context';
import SelectDataset from './SubHeaders/SelectDataset';
import { RightButton } from '../../../Common/global/styles';

const Header: React.FC = (): JSX.Element => {
  const { currentScreen, setCurrentScreen } = useContext(NavigationContext);
  const { currentDraft } = useContext(DataContext);

  const isCurrentDraftEmpty = !currentDraft;

  const handleNewTemplateClick = () => {
    setCurrentScreen(Screens.DraftConfiguration);
    // TODO: Clear currentDraft from state
  };

  const handleOpenSavedDraftsClick = () => {
    setCurrentScreen(Screens.DraftTemplates);
    // TODO: Clear currentDraft from state
  };

  return (
    <HeaderWrapper>
      <TopHeader>
        <LeftSection>
          <Title>{text.headerTitle}</Title>
        </LeftSection>
        <RightSection>
          <RightButton icon={ICON_TYPES.X_CIRCLE_FILLED} size={Button.SIZES.SMALL}>
            {text.headerExitButton}
          </RightButton>
        </RightSection>
      </TopHeader>

      <SubHeader>
        {currentScreen === Screens.DraftTemplates && (
          <Initial handleNewTemplateClick={handleNewTemplateClick} />
        )}
        {currentScreen === Screens.DraftConfiguration && isCurrentDraftEmpty && (
          <SelectDataset handleOpenSavedDraftsClick={handleOpenSavedDraftsClick} />
        )}
        {currentScreen === Screens.DraftConfiguration && !isCurrentDraftEmpty && (
          <SourceContent
            handleNewTemplateClick={handleNewTemplateClick}
            handleOpenSavedDraftsClick={handleOpenSavedDraftsClick}
          />
        )}
      </SubHeader>
    </HeaderWrapper>
  );
};

export default Header;
