import { useState } from 'react';
import { MetaInfo } from '../../../../lib/interfaces/templates';

interface UseSourceContentState {
  topLevelFolder: MetaInfo;
  setTopLevelFolder: React.Dispatch<React.SetStateAction<MetaInfo>>;
  iPJ: string;
  setIPJ: React.Dispatch<React.SetStateAction<string>>;
  topLevelAssembly: string | undefined;
  setTopLevelAssembly: React.Dispatch<React.SetStateAction<string>>;
  topLevelAssemblyPath: string;
  setTopLevelAssemblyPath: React.Dispatch<React.SetStateAction<string>>;
  thumbnail: string;
  setThumbnail: React.Dispatch<React.SetStateAction<string>>;
  isPreviewStep: boolean;
  setPreviewStep: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useSourceContent = (): UseSourceContentState => {
  const [topLevelFolder, setTopLevelFolder] = useState<MetaInfo>({ id: '', name: '' });
  const [iPJ, setIPJ] = useState('');
  const [topLevelAssembly, setTopLevelAssembly] = useState('');
  const [topLevelAssemblyPath, setTopLevelAssemblyPath] = useState('');
  const [thumbnail, setThumbnail] = useState<string>('');
  const [isPreviewStep, setPreviewStep] = useState(false);

  return {
    topLevelFolder,
    setTopLevelFolder,
    iPJ,
    setIPJ,
    topLevelAssembly,
    setTopLevelAssembly,
    topLevelAssemblyPath,
    setTopLevelAssemblyPath,
    thumbnail,
    setThumbnail,
    isPreviewStep,
    setPreviewStep,
  };
};

export default useSourceContent;
