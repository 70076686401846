import React, { useState } from 'react';
import { actions, Column, OnAction, SetTableOptions, Table } from '@adsk/alloy-react-table';
import { format } from 'date-fns';
import {
  DraftTableWrapper,
  DraftTemplateTableHeader,
  DraftTemplateTableSummary,
  SelectedText,
  TableHeaderButton,
} from './styles';
import { DraftTableDataModel, DraftTableProps } from './types';
import { ICON_TYPES } from '@adsk/alloy-react';

const templateNameColumnKey = 'templateName';

export const DraftTable: React.FC<DraftTableProps> = ({
  drafts,
  handleEditTemplateClick,
}): JSX.Element => {
  const [selectedDraftIds, setSelectedDraftIds] = useState<Record<string, boolean>>({});
  const numberOfDraftsSelected = Object.keys(selectedDraftIds).length;

  const setTableOptions: SetTableOptions<Record<string, any>> = (opts: any) => ({
    ...opts,
    getRowId: (row: DraftTableDataModel) => row[templateNameColumnKey],
  });

  const tableData: DraftTableDataModel[] = drafts.map((d) => ({
    [templateNameColumnKey]: d.name,
    numberOfInputs: d.parameters.length,
    publishTo: `${d.account.name} / ${d.project.name} / ${d.folder.name}`,
    lastUpdated: format(d.lastUpdated, 'MMM d, yyyy h:m a'),
  }));

  const columns: Column<DraftTableDataModel>[] = [
    {
      id: templateNameColumnKey,
      accessor: templateNameColumnKey,
      renderHeader: () => 'Template name',
    },
    {
      id: 'numberOfInputs',
      accessor: 'numberOfInputs',
      renderHeader: () => '# inputs',
    },
    {
      id: 'publishTo',
      accessor: 'publishTo',
      renderHeader: () => 'Publish to',
    },
    {
      id: 'lastUpdated',
      accessor: 'lastUpdated',
      renderHeader: () => 'Last updated',
    },
  ];
  const handleEditTemplateClickWithContext = (): void => {
    if (numberOfDraftsSelected !== 1) {
      // TODO: Replace error thrown with Error modal
      // once we have it
      throw new Error('Cannot edit more than one Draft at a time.');
    }

    const draftTemplateName = Object.keys(selectedDraftIds)[0];
    const draft = drafts.find((draft) => draft.name === draftTemplateName);
    if (draft) {
      handleEditTemplateClick(draft);
    } else {
      // TODO: Replace error thrown with Error modal
      // once we have it
      throw new Error('Could not find the Draft based on the currently selected row.');
    }
  };

  const onAction: OnAction<Record<string, any>> = ({ action, state }) => {
    if (action.type === actions.toggleRowSelected) {
      setSelectedDraftIds(state.selectedRowIds);
    }
    if (action.type === actions.toggleAllRowsSelected) {
      setSelectedDraftIds(state.selectedRowIds);
    }
  };

  return (
    <DraftTableWrapper>
      <DraftTemplateTableHeader>
        <DraftTemplateTableSummary data-testid="draft-template-table-summary">
          {drafts.length} templates in <b>Saved drafts</b>
        </DraftTemplateTableSummary>
        <SelectedText data-testid="selected-text">
          {Object.keys(selectedDraftIds).length} selected
        </SelectedText>
        <TableHeaderButton
          disabled={numberOfDraftsSelected !== 1}
          icon={ICON_TYPES.PENCIL}
          onClick={handleEditTemplateClickWithContext}
        >
          Edit
        </TableHeaderButton>
        <TableHeaderButton disabled={numberOfDraftsSelected === 0} icon={ICON_TYPES.TRASH_CAN}>
          Delete
        </TableHeaderButton>
      </DraftTemplateTableHeader>
      <Table
        columns={columns as Column<Record<string, DraftTableDataModel>>[]}
        data={tableData as Record<string, any>[]}
        isMultiSelectable
        isResizable
        isSortable
        selectedRowIds={selectedDraftIds}
        setTableOptions={setTableOptions}
        onAction={onAction}
      />
    </DraftTableWrapper>
  );
};
