import React from 'react';
import { SourceContentForm, SourceContentFormWrapper } from './styles';
import SourceContentTabHeader from './Header/SourceContentTabHeader';
import TopFolderSection from './FormStep/TopFolderSection';
import Preview from './PreviewStep/Preview';
import IPJSection from './FormStep/IPJSection';
import AssemblySection from './FormStep/AssemblySection';
import useSourceContent from './useSourceContent';

const SourceContentTab: React.FC = (): JSX.Element => {
  const {
    topLevelFolder,
    setTopLevelFolder,
    iPJ,
    setIPJ,
    topLevelAssembly,
    setTopLevelAssembly,
    topLevelAssemblyPath,
    setTopLevelAssemblyPath,
    thumbnail,
    isPreviewStep,
    setPreviewStep,
  } = useSourceContent();

  return (
    <>
      <SourceContentTabHeader
        enableNextButton={!!topLevelFolder.id && !!iPJ && !!topLevelAssembly}
        setPreviewStep={setPreviewStep}
        isPreviewStep={isPreviewStep}
      />
      {isPreviewStep ? (
        topLevelFolder.name &&
        iPJ &&
        topLevelAssembly &&
        topLevelAssemblyPath && (
          <Preview
            topLevelFolder={topLevelFolder.name}
            iPJ={iPJ}
            topLevelAssembly={topLevelAssembly}
            path={topLevelAssemblyPath}
            thumbnail={thumbnail}
          />
        )
      ) : (
        <SourceContentFormWrapper>
          <SourceContentForm>
            <TopFolderSection
              topLevelFolder={topLevelFolder}
              setTopLevelFolder={setTopLevelFolder}
            />
            {topLevelFolder.id && (
              <>
                <IPJSection iPJ={iPJ} setIPJ={setIPJ} />
                <AssemblySection
                  topLevelAssembly={topLevelAssembly}
                  setTopLevelAssembly={setTopLevelAssembly}
                  setTopLevelAssemblyPath={setTopLevelAssemblyPath}
                  iPJ={iPJ}
                />
              </>
            )}
          </SourceContentForm>
        </SourceContentFormWrapper>
      )}
    </>
  );
};

export default SourceContentTab;
