import { StateReducer } from '@adsk/alloy-react-table/es/stateReducer';
import { useEffect, useState } from 'react';
import { InventorParameter, IProperty } from '../../../../../lib/interfaces/templates';
import { actions, Column, SetTableOptions } from '@adsk/alloy-react-table';
import { TableOptions } from 'react-table';
export interface UseInputsTableState {
  tableData: InventorParameter[] | IProperty[];
  tableColumns: Column<Record<string, InventorParameter>>[] | Column<Record<string, IProperty>>[];
  selectedIds: {};
  setTableOptions: SetTableOptions<Record<string, any>>;
  handleOnAction: (reducer: StateReducer<Record<string, any>>) => void;
}

export interface UseInputsTableArgs {
  initialTableData: InventorParameter[] | IProperty[];
  rowIdKey: string;
  tableColumns: Column<Record<string, InventorParameter>>[] | Column<Record<string, IProperty>>[];
  setSelectedIds: React.Dispatch<React.SetStateAction<{}>>;
  filterFunc: Function;
  selectedIds: {};
  currentFilter: string;
}

export const useInputsTable = ({
  initialTableData,
  rowIdKey,
  tableColumns,
  selectedIds,
  setSelectedIds,
  currentFilter,
  filterFunc,
}: UseInputsTableArgs): UseInputsTableState => {
  const [tableData, setTableData] = useState<InventorParameter[] | IProperty[]>(initialTableData);
  // This function is used to set custom row id
  // By default, table component is using row index (0,1,2...) as rowId.
  const setTableOptions: SetTableOptions<Record<string, any>> = (
    opts: TableOptions<Record<string, any>>,
  ): TableOptions<Record<string, any>> => ({
    ...opts,
    getRowId: (row: any) => row[rowIdKey],
  });

  // When user toggle the checkbox on each row, it will fire this event.
  // There will more action types to be used for different scenarios.
  // For our use case, we only care about toggleRowSelected and toggleAllRowsSelected.
  const handleOnAction = (reducer: StateReducer<Record<string, any>>) => {
    if ([actions.toggleRowSelected, actions.toggleAllRowsSelected].includes(reducer.action.type)) {
      setSelectedIds(reducer.state.selectedRowIds);
    }
  };

  useEffect(() => {
    const filteredData = filterFunc(initialTableData, currentFilter, selectedIds);
    setTableData(filteredData);
  }, [currentFilter, filterFunc, initialTableData, selectedIds]);

  return {
    tableData,
    tableColumns,
    selectedIds,
    setTableOptions,
    handleOnAction,
  };
};
