import React from 'react';
import { SourceContentTextInput, SourceContentRightButton } from '../styles';
import text from '../../../../../Common/global/text/text.json';
import { Button } from '@adsk/alloy-react';
import { mockFetchAssemblyService } from './mockServices';
import { LabelText, MIDFormField } from '../../../../../Common/global/styles';

interface AssemblySectionProps {
  topLevelAssembly: string | undefined;
  setTopLevelAssembly: React.Dispatch<React.SetStateAction<string>>;
  setTopLevelAssemblyPath: React.Dispatch<React.SetStateAction<string>>;
  iPJ: string;
}

const AssemblySection: React.FC<AssemblySectionProps> = ({
  topLevelAssembly,
  setTopLevelAssembly,
  setTopLevelAssemblyPath,
  iPJ,
}): JSX.Element => {
  const handleSelectOrReplaceClick = async () => {
    // It will call a service that fetches Top-Level Assembly
    const { assembly, assemblyPath } = await mockFetchAssemblyService();
    setTopLevelAssembly(assembly);
    setTopLevelAssemblyPath(assemblyPath);
  };

  return (
    <MIDFormField required label={text.sourceContentTopLevelAssemblyLabel} labelVariant="top">
      <LabelText>
        <small>{text.sourceContentTopLevelAssemblyComment}</small>
      </LabelText>
      <SourceContentTextInput
        type="text"
        placeholder="Not selected"
        data-testid="top-assembly-input"
        readOnly
        defaultValue={topLevelAssembly}
      />
      {topLevelAssembly ? (
        <SourceContentRightButton
          data-testid="top-assembly-replace-button"
          onClick={handleSelectOrReplaceClick}
          size={Button.SIZES.MEDIUM}
        >
          {text.buttonReplace}
        </SourceContentRightButton>
      ) : (
        <SourceContentRightButton
          data-testid="top-assembly-select-button"
          onClick={handleSelectOrReplaceClick}
          variant={iPJ ? Button.VARIANTS.PRIMARY : Button.VARIANTS.SECONDARY}
          size={Button.SIZES.MEDIUM}
        >
          {text.buttonSelect}
        </SourceContentRightButton>
      )}
    </MIDFormField>
  );
};

export default AssemblySection;
