import React from 'react';
import useEditInputs from './useEditInputs';
import { InputsEditMenu } from './InputsEditMenu';
import { ParameterInfo } from './ParameterInfo';
import { IPropertyInfo } from './iPropertyInfo';
import { EditInputsView } from './styles';
import { InventorProperties } from '../../../../../lib/interfaces/templates';

interface EditInputsProp {
  setIsEditStep: React.Dispatch<React.SetStateAction<boolean>>;
  selectedInputs: InventorProperties;
}

const EditInputs: React.FC<EditInputsProp> = ({ setIsEditStep, selectedInputs }): JSX.Element => {
  const {
    parameterInfo,
    setParameterInfo,
    iPropertyInfo,
    setiPropertyInfo,
    handleParameterLabelChange,
    handleiPropertyLabelChange,
  } = useEditInputs(selectedInputs);
  return (
    <EditInputsView>
      <InputsEditMenu
        selectedInputs={selectedInputs}
        setIsEditStep={setIsEditStep}
        setParameterInfo={setParameterInfo}
        setIpropertyInfo={setiPropertyInfo}
        parameterInfo={parameterInfo}
        iPropertyInfo={iPropertyInfo}
      />
      {(parameterInfo && (
        <ParameterInfo parameter={parameterInfo} handleLabelChange={handleParameterLabelChange} />
      )) ||
        (iPropertyInfo && (
          <IPropertyInfo iProperty={iPropertyInfo} handleLabelChange={handleiPropertyLabelChange} />
        ))}
    </EditInputsView>
  );
};

export default EditInputs;
