import { Button, MenuItem, MenuList, TextInput } from '@adsk/alloy-react';
import styled from 'styled-components';

export const EditMenuComponent = styled.div`
  display: block;
  width: 20%;
  min-width: 200px;
  padding: ${({ theme }) => `${theme.vars.paddingBase * 2}px`};
`;

export const EditMenuButtons = styled(Button)`
  width: 100%;
  margin-bottom: ${({ theme }) => `${theme.vars.marginBase / 2}px`};
`;

export const InputMenu = styled(MenuList)`
  overflow: scroll;
  margin-top: ${({ theme }) => `${theme.vars.marginBase / 2}px`};
  padding: 0;
  border: 1px solid ${({ theme }) => theme.colors.charcoal200};
  height: 60vh;
`;

export const InputItem = styled(MenuItem)`
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.charcoal100};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.adskBlue300};
  }
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.adskBlue500 : undefined} !important;
`;

export const ItemCount = styled.p`
  margin: 0;
  padding-top: ${({ theme }) => `${theme.vars.paddingBase / 2}px`};
  text-align: right;
  ${({ theme }) => theme.typography.bodyMedium}
`;

export const EditingContainer = styled.div`
  width: 100%;
  margin: ${({ theme }) => `${theme.vars.marginBase}px`} 0 0 0;
  padding: ${({ theme }) => `${theme.vars.paddingBase * 3}px`};
  background-color: #eeeeee;
  display: block;
`;

export const Block = styled.div`
  padding: ${({ theme }) => `${theme.vars.paddingBase}px`};
  display: flex;
`;

export const EditInputsView = styled.div`
  display: flex;
`;

export const EditInputsContainer = styled.div`
  padding: ${({ theme }) => `${theme.vars.paddingBase * 2}px`} 0
    ${({ theme }) => `${theme.vars.paddingBase * 2}px`}
    ${({ theme }) => `${theme.vars.paddingBase * 2}px`};
  width: 100%;
  display: inline-block;
`;

export const ParameterTitle = styled.h1`
  margin: 0;
  ${({ theme }) => theme.typography.heading2}
`;

export const LeftContainer = styled.div`
  display: flex;
  width: 65%;
`;

export const InputField = styled.div`
  width: 100%;
  margin: ${({ theme }) => `${theme.vars.marginBase / 2}px`};
  top: 0;
`;

export const TextBox = styled(TextInput)`
  padding-top: ${({ theme }) => `${theme.vars.paddingBase / 2}px`};
`;

export const LabelTitle = styled.label`
  display: block;
  ${({ theme }) => theme.typography.bodyMedium}
`;

export const RightContainer = styled.div`
  width: 35%;
  display: flex;
  justify-content: flex-end;
`;

export const ParamLabelRight = styled.div`
  width: 40%;
  margin: ${({ theme }) => `${theme.vars.marginBase / 2}px`};
`;
