import { Button, ICON_TYPES } from '@adsk/alloy-react';
import React from 'react';
import { LeftSection, RightSection, Subtitle } from '../styles';
import text from '../../../../Common/global/text/text.json';
import { RightButton } from '../../../../Common/global/styles';

const Initial: React.FC<{
  handleNewTemplateClick: () => void;
}> = ({ handleNewTemplateClick }): JSX.Element => (
  <>
    <LeftSection>
      <Subtitle>{text.subHeaderTitleInitial}</Subtitle>
    </LeftSection>
    <RightSection>
      <RightButton
        icon={ICON_TYPES.PLUS_CIRCLE_FILLED}
        size={Button.SIZES.SMALL}
        variant={Button.VARIANTS.PRIMARY}
        onClick={handleNewTemplateClick}
      >
        {text.buttonNewTemplate}
      </RightButton>
    </RightSection>
  </>
);

export default Initial;
