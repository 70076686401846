import React from 'react';
import { SourceContentPreviewImage, SourceContentTable, SourceContentTitle } from '../styles';
import text from '../../../../../Common/global/text/text.json';
import { Tooltip } from '@adsk/alloy-react';
import { TooltipIcon, TooltipWrapper } from '../../../../../Common/global/styles';

interface PreviewProps {
  topLevelFolder: string;
  iPJ: string;
  topLevelAssembly: string;
  path: string;
  thumbnail: string;
}

const Preview: React.FC<PreviewProps> = ({
  topLevelFolder,
  iPJ,
  topLevelAssembly,
  path,
  thumbnail,
}): JSX.Element => (
  <>
    <SourceContentTable>
      <tbody>
        <tr>
          <td>{text.sourceContentTopLevelFolderLabel}</td>
          <td>{topLevelFolder}</td>
        </tr>
        <tr>
          <td>{text.sourceContentIPJLabel}</td>
          <td>{iPJ}</td>
        </tr>
        <tr>
          <td>{text.sourceContentTopLevelAssemblyLabel}</td>
          <td>{topLevelAssembly}</td>
        </tr>
        <tr>
          <td>{text.sourceContentPath}</td>
          <td>{path}</td>
        </tr>
      </tbody>
    </SourceContentTable>
    <SourceContentTitle>
      {text.sourceContentPreviewThumbnail}
      <TooltipWrapper>
        <Tooltip content={text.sourceContentPreviewTooltip}>
          <TooltipIcon type="questionCircleFilled" fr={0} />
        </Tooltip>
      </TooltipWrapper>
    </SourceContentTitle>
    <SourceContentPreviewImage src={thumbnail} alt={text.sourceContentPreviewImageAltText} />
  </>
);

export default Preview;
