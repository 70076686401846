import React, { useState } from 'react';

export enum Screens {
  DraftTemplates = 'DraftTemplates',
  DraftConfiguration = 'DraftConfiguration',
}

export type StoreSetterType<T> = React.Dispatch<React.SetStateAction<T>>;

export interface NavigationStore {
  currentScreen: Screens;
  setCurrentScreen: StoreSetterType<Screens>;
}

export const useNavigationStore = (): NavigationStore => {
  const [currentScreen, setCurrentScreen] = useState(Screens.DraftTemplates);

  return {
    currentScreen,
    setCurrentScreen,
  };
};
