import React from 'react';
import text from '../../../../../Common/global/text/text.json';
import { InputsFilterDropDown, InputsHeaderWrapper } from './styles';
import { Button, ButtonGroup, ICON_TYPES } from '@adsk/alloy-react';
import InputsFilter from './InputsFilter';
import { InputsHeaderProps } from './types';

const InputsHeader: React.FC<InputsHeaderProps> = ({
  buttonGroupValue,
  handleOnParameterOriPropertyButtonChange,
  currentFilter,
  setCurrentFilter,
  filterItems,
  totalSelected,
  handleOnAddInputsButtonClick,
}): JSX.Element => (
  <InputsHeaderWrapper>
    <ButtonGroup<string>
      onChange={handleOnParameterOriPropertyButtonChange}
      value={buttonGroupValue}
      data-testid="test-group-button-id"
    >
      <Button value={text.inputsParameters} data-testid="test-parameters-button-id">
        {text.inputsParameters}
      </Button>
      <Button value={text.inputsIProperties} data-testid="test-iproperties-button-id">
        {text.inputsIProperties}
      </Button>
    </ButtonGroup>
    <InputsFilterDropDown>
      <InputsFilter
        items={filterItems}
        initialSelectedItem={currentFilter}
        setFilter={setCurrentFilter}
      />
    </InputsFilterDropDown>
    <Button
      data-testid="test-add-inputs-button-id"
      icon={ICON_TYPES.PLUS_CIRCLE_FILLED}
      variant={Button.VARIANTS.PRIMARY}
      disabled={!totalSelected}
      onClick={handleOnAddInputsButtonClick}
    >
      {totalSelected
        ? `${text.addInputsButtonTextAdd} ${totalSelected} ${text.addInputsButtonTextInputs}`
        : `${text.addInputsButtonTextAdd} ${text.addInputsButtonTextInputs}`}
    </Button>
  </InputsHeaderWrapper>
);

export default InputsHeader;
