import { Icon, Tooltip } from '@adsk/alloy-react';
import React from 'react';
import { TooltipIcon, TooltipWrapper } from '../../../../../Common/global/styles';
import text from '../../../../../Common/global/text/text.json';
import {
  Block,
  EditingContainer,
  EditInputsContainer,
  InputField,
  LabelTitle,
  LeftContainer,
  ParameterTitle,
  ParamLabelRight,
  RightContainer,
  TextBox,
} from './styles';
import { IPropertyInfoProps } from './types';

export const IPropertyInfo: React.FC<IPropertyInfoProps> = ({
  iProperty,
  handleLabelChange,
}): JSX.Element => (
  <EditInputsContainer>
    <ParameterTitle>
      {text.editInputsTitle} <strong>{iProperty.displayName}</strong>
    </ParameterTitle>
    <EditingContainer>
      <Block>
        <LeftContainer>
          <InputField>
            <LabelTitle>{text.editInputsiPropertyName}</LabelTitle>
            <TextBox value={iProperty.displayName} disabled />
          </InputField>
          <InputField>
            <LabelTitle>
              {text.editInputsLabel}
              <TooltipWrapper>
                <Tooltip content={text.editInputsLabelTooltip}>
                  <TooltipIcon type={Icon.TYPES.QUESTION_CIRCLE_FILLED} size={20} fr={0} />
                </Tooltip>
              </TooltipWrapper>
            </LabelTitle>
            <TextBox
              key={iProperty.displayName}
              defaultValue={iProperty.label || iProperty.displayName}
              onChange={handleLabelChange(iProperty.displayName)}
            />
          </InputField>
        </LeftContainer>
        <RightContainer>
          <ParamLabelRight>
            <LabelTitle>{text.editInputsiPropertyType}</LabelTitle>
            <TextBox value={iProperty.category} disabled />
          </ParamLabelRight>
        </RightContainer>
      </Block>
      <Block>
        <LeftContainer>
          <InputField>
            <LabelTitle>{text.editInputsiPropertyValue}</LabelTitle>
            <TextBox value={iProperty.value} disabled />
          </InputField>
        </LeftContainer>
      </Block>
    </EditingContainer>
  </EditInputsContainer>
);
