import { useState } from 'react';
import {
  IProperty,
  InventorParameter,
  InventorProperties,
} from '../../../../../lib/interfaces/templates';

interface UseEditInputsState {
  parameterInfo: InventorParameter | null;
  setParameterInfo: React.Dispatch<React.SetStateAction<InventorParameter | null>>;
  iPropertyInfo: IProperty | null;
  setiPropertyInfo: React.Dispatch<React.SetStateAction<IProperty | null>>;
  handleParameterLabelChange: (
    paramName: string,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleiPropertyLabelChange: (
    paramName: string,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const useEditInputs = (selectedInputs: InventorProperties): UseEditInputsState => {
  const [parameterInfo, setParameterInfo] = useState<InventorParameter | null>(
    selectedInputs.parameters.at(0) ?? null,
  );
  const [iPropertyInfo, setiPropertyInfo] = useState<IProperty | null>(
    selectedInputs.iProperties.at(0) ?? null,
  );
  const paramLabelMap: Map<string, string> = new Map<string, string>();
  const iPropLabelMap: Map<string, string> = new Map<string, string>();

  const handleParameterLabelChange =
    (paramName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newLabel = event.target.value;
      paramLabelMap.set(paramName, newLabel);
      // TODO: Why do we need this map? We are iterating through all
      // even after we found the input we want. In addition, this map is directly
      // mutating state data rather than using `setSelectedInputs`
      selectedInputs.parameters.map((param: InventorParameter) => {
        if (paramLabelMap.has(param.name)) {
          param.label = paramLabelMap.get(param.name);
        }
      });
    };

  const handleiPropertyLabelChange =
    (paramName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newLabel = event.target.value;
      iPropLabelMap.set(paramName, newLabel);
      // TODO: Why do we need this map? We are iterating through all
      // even after we found the input we want. In addition, this map is directly
      // mutating state data rather than using `setSelectedInputs`
      selectedInputs.iProperties.map((param: IProperty) => {
        if (iPropLabelMap.has(param.displayName)) {
          param.label = iPropLabelMap.get(param.displayName);
        }
      });
    };

  return {
    parameterInfo,
    setParameterInfo,
    iPropertyInfo,
    setiPropertyInfo,
    handleParameterLabelChange,
    handleiPropertyLabelChange,
  };
};

export default useEditInputs;
