import { Button, Icon } from '@adsk/alloy-react';
import React from 'react';
import text from '../../../../../Common/global/text/text.json';
import { InventorParameter, IProperty } from '../../../../../lib/interfaces/templates';
import { InputItem, InputMenu, ItemCount, EditMenuButtons, EditMenuComponent } from './styles';
import { InputsEditMenuProps } from './types';

export const InputsEditMenu: React.FC<InputsEditMenuProps> = ({
  selectedInputs,
  parameterInfo,
  iPropertyInfo,
  setParameterInfo,
  setIpropertyInfo,
  setIsEditStep,
}): JSX.Element => {
  const countSelectedItems = (): number =>
    selectedInputs.iProperties.length + selectedInputs.parameters.length;

  const handleChangeSelectedInputs = () => {
    setIsEditStep(false);
  };

  const handleItemSelection = ({
    parameter,
    iProperty,
  }: {
    parameter?: InventorParameter;
    iProperty?: IProperty;
  }) => {
    if (parameter) {
      setParameterInfo(parameter);
      setIpropertyInfo(null);
    }
    if (iProperty) {
      setParameterInfo(null);
      setIpropertyInfo(iProperty);
    }
  };

  const handlePreviewRules = () => undefined;

  return (
    <EditMenuComponent>
      <EditMenuButtons
        size={Button.SIZES.SMALL}
        onClick={handleChangeSelectedInputs}
        icon={Icon.TYPES.PLUS_CIRCLE_FILLED}
        variant={Button.VARIANTS.PRIMARY}
      >
        {text.buttonChangeInputs}
      </EditMenuButtons>
      <EditMenuButtons
        size={Button.SIZES.SMALL}
        onClick={handlePreviewRules}
        icon={Icon.TYPES.CALCULATOR}
      >
        {text.buttonPreviewRules}
      </EditMenuButtons>
      <InputMenu>
        {selectedInputs.parameters.map((parameter) => {
          const isSelected = parameterInfo?.name === parameter.name;
          return (
            <InputItem
              showSelection={false}
              selected={isSelected}
              key={parameter.name}
              label={parameter.name}
              onClick={() => handleItemSelection({ parameter })}
            />
          );
        })}
        {selectedInputs.iProperties.map((iProperty) => {
          const isSelected = !parameterInfo && iPropertyInfo?.id === iProperty.id;
          return (
            <InputItem
              showSelection={false}
              selected={isSelected}
              key={iProperty.id}
              label={iProperty.displayName}
              icon={Icon.TYPES.PENCIL_FILLED}
              onClick={() => handleItemSelection({ iProperty })}
            />
          );
        })}
      </InputMenu>
      <ItemCount>{countSelectedItems()} selected</ItemCount>
    </EditMenuComponent>
  );
};
