import React from 'react';
import { SourceContentTextInput, SourceContentRightButton } from '../styles';
import text from '../../../../../Common/global/text/text.json';
import { Button, Tooltip } from '@adsk/alloy-react';
import {
  Label,
  LabelText,
  LabelTextRequired,
  TooltipIcon,
  TooltipWrapper,
} from '../../../../../Common/global/styles';
import { mockFetchTopFolderService } from './mockServices';
import { MetaInfo } from '../../../../../lib/interfaces/templates';

interface TopFolderSectionProps {
  topLevelFolder: MetaInfo;
  setTopLevelFolder: React.Dispatch<React.SetStateAction<MetaInfo>>;
}

const TopFolderSection: React.FC<TopFolderSectionProps> = ({
  topLevelFolder,
  setTopLevelFolder,
}): JSX.Element => {
  const handleSelectOrReplaceClick = async () => {
    // It will call a service that fetches Top-Level Folder
    const { folder } = await mockFetchTopFolderService();

    setTopLevelFolder(folder);
  };

  return (
    <Label>
      <LabelText>
        {text.sourceContentTopLevelFolderLabel}
        <LabelTextRequired>*</LabelTextRequired>
        <TooltipWrapper>
          <Tooltip content={text.sourceContentTopLevelTooltip}>
            <TooltipIcon type="alertInformationFilled" fr={0} />
          </Tooltip>
        </TooltipWrapper>
      </LabelText>
      <SourceContentTextInput
        type="text"
        placeholder="Not selected"
        data-testid="top-folder-input"
        readOnly
        defaultValue={topLevelFolder.name}
      />
      {topLevelFolder.name ? (
        <SourceContentRightButton
          data-testid="top-folder-replace-button"
          onClick={handleSelectOrReplaceClick}
          size={Button.SIZES.MEDIUM}
        >
          {text.buttonReplace}
        </SourceContentRightButton>
      ) : (
        <SourceContentRightButton
          data-testid="top-folder-select-button"
          onClick={handleSelectOrReplaceClick}
          variant={Button.VARIANTS.PRIMARY}
          size={Button.SIZES.MEDIUM}
        >
          {text.buttonSelect}
        </SourceContentRightButton>
      )}
    </Label>
  );
};

export default TopFolderSection;
