import alloyTheme from '@adsk/alloy-react-theme';

export default {
  vars: {
    marginBase: 10,
    paddingBase: 10,
    wideDropDown: 300,
    narrowDropDown: 150,
    tableHeight: 600,
  },
  colors: { ...alloyTheme.colors },
  typography: { ...alloyTheme.typography },
};
