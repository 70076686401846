import { TextInput } from '@adsk/alloy-react';
import styled from 'styled-components';
import { RightButton } from '../../../../Common/global/styles';

export const SourceContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SourceContentTitle = styled.h4`
  ${({ theme }) => theme.typography.heading4}
`;

export const SourceContentRightButton = styled(RightButton)`
  margin-left: ${({ theme }) => `${theme.vars.marginBase}px`};
  margin-bottom: 4px; // To better alignment with input
  vertical-align: bottom;
`;

export const SourceContentFormWrapper = styled.div`
  padding: ${({ theme }) => `${theme.vars.paddingBase * 2}px`};
  background-color: ${({ theme }) => theme.colors.charcoal100};
`;

export const SourceContentTableWrapper = styled.div`
  padding: ${({ theme }) => `${theme.vars.paddingBase * 2}px`};
`;

export const SourceContentForm = styled.form`
  display: inline-block;
`;

export const SourceContentTextInput = styled(TextInput)`
  width: 300px;
  display: inline-block;
  vertical-align: top;
`;

export const SourceContentTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  ${({ theme }) => theme.typography.bodyMedium}

  tr:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.charcoal100};
  }

  td {
    text-align: left;
    padding: ${({ theme }) => `${theme.vars.paddingBase}px`};
  }

  td:first-child {
    width: 30%;
  }
`;

export const SourceContentPreviewImage = styled.img`
  width: 300px;
  height: auto;
`;
