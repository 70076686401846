import { Icon, Tooltip } from '@adsk/alloy-react';
import React from 'react';
import { TooltipIcon, TooltipWrapper } from '../../../../../Common/global/styles';
import text from '../../../../../Common/global/text/text.json';
import {
  Block,
  EditingContainer,
  EditInputsContainer,
  InputField,
  LabelTitle,
  LeftContainer,
  ParameterTitle,
  ParamLabelRight,
  RightContainer,
  TextBox,
} from './styles';
import { ParameterInfoProps } from './types';

export const ParameterInfo: React.FC<ParameterInfoProps> = ({
  parameter,
  handleLabelChange,
}): JSX.Element => (
  <EditInputsContainer>
    <ParameterTitle>
      {text.editInputsTitle}
      <strong> {parameter.name}</strong>
    </ParameterTitle>
    <EditingContainer>
      <Block>
        <LeftContainer>
          <InputField>
            <LabelTitle>{text.editInputsParameterName}</LabelTitle>
            <TextBox value={parameter.name} disabled />
          </InputField>
          <InputField>
            <LabelTitle>
              {text.editInputsLabel}
              <TooltipWrapper>
                <Tooltip content={text.editInputsLabelTooltip}>
                  <TooltipIcon type={Icon.TYPES.QUESTION_CIRCLE_FILLED} size={20} fr={0} />
                </Tooltip>
              </TooltipWrapper>
            </LabelTitle>
            <TextBox
              key={parameter.name}
              defaultValue={parameter.label || parameter.name}
              onChange={handleLabelChange(parameter.name)}
            />
          </InputField>
        </LeftContainer>
        <RightContainer>
          <ParamLabelRight>
            <LabelTitle>{text.editInputsParameterUnits}</LabelTitle>
            <TextBox value={parameter.unitType} disabled />
          </ParamLabelRight>
        </RightContainer>
      </Block>
    </EditingContainer>
  </EditInputsContainer>
);
