import React, { useContext } from 'react';
import { DraftTable } from '../components/DraftTable/DraftTable';

import { mockDrafts } from '../../lib/utils/tests/mockData';
import { Screens } from '../context/navigationStore';
import NavigationContext from '../context/Navigation.context';
import { DraftTemplate } from '../../lib/interfaces/templates';

export const DraftTemplatesScreen: React.FC = (): JSX.Element => {
  const { setCurrentScreen } = useContext(NavigationContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleEditTemplateClick = (draftTemplate: DraftTemplate) => {
    setCurrentScreen(Screens.DraftConfiguration);
    // TODO: Set currentDraft in the DataStore
  };
  // TODO: Query drafts from JS lib when ready
  return <DraftTable drafts={mockDrafts} handleEditTemplateClick={handleEditTemplateClick} />;
};
