import { useState } from 'react';
import { DraftTemplate } from '../../lib/interfaces/templates';
import { StoreSetterType } from './navigationStore';

export interface DataStore {
  currentDraft: DraftTemplate | null;
  setCurrentDraft: StoreSetterType<DraftTemplate | null>;
}

export const useStore = (): DataStore => {
  const [currentDraft, setCurrentDraft] = useState<DraftTemplate | null>(null);
  return {
    currentDraft,
    setCurrentDraft,
  };
};
