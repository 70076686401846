import React, { useContext } from 'react';
import { Dropdown } from '../../../../../Common/components/Dropdown/Dropdown';
import { InputsFilterProps } from './types';
import { ThemeContext } from 'styled-components';
import { ICON_TYPES } from '@adsk/alloy-react';
import { DropdownItem } from '../../../../../Common/components/Dropdown/types';

const InputsFilter: React.FC<InputsFilterProps> = ({
  items,
  initialSelectedItem,
  setFilter,
}): JSX.Element => {
  const theme = useContext(ThemeContext);
  const handleOnSelect = (changes: { selectedItem?: DropdownItem | null }) =>
    setFilter(changes.selectedItem?.value || '');
  const mapDropdownElement = items.map((item) => ({ label: item, value: item }));
  return (
    <Dropdown
      width={theme.vars.narrowDropDown}
      elements={mapDropdownElement}
      selectedItem={{ label: initialSelectedItem, value: initialSelectedItem }}
      controlIcon={ICON_TYPES.FUNNEL}
      onSelect={handleOnSelect}
    />
  );
};

export default InputsFilter;
