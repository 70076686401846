import { Button, FormField, Icon } from '@adsk/alloy-react';
import styled from 'styled-components';

export const LeftButton = styled(Button)`
  margin-right: ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const RightButton = styled(Button)`
  margin-left: ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const Label = styled.label`
  display: block;
  ${({ theme }) => theme.typography.labelMedium}
  margin-bottom: ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const LabelText = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.charcoal700};
  margin-bottom: ${({ theme }) => `${theme.vars.marginBase / 2}px`};
  ${({ theme }) => theme.typography.labelSmall}
`;

export const LabelTextRequired = styled.span`
  color: ${({ theme }) => theme.colors.red600};
  margin-left: 3px;
`;

export const TooltipWrapper = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-left: ${({ theme }) => `${theme.vars.marginBase / 2}px`};
`;

export const TooltipIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.black};
  vertical-align: top;
`;

export const MIDFormField = styled(FormField)`
  margin-bottom: ${({ theme }) => `${theme.vars.marginBase}px`};
`;

export const TableWrapper = styled.div`
  ${({ theme }) => theme.typography.bodyMedium}
  height: ${({ theme }) => `${theme.vars.tableHeight}px`};
`;
