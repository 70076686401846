import { Tab, Tabs } from '@adsk/alloy-react';
import React from 'react';
import InputsTab from '../components/Tabs/Inputs/InputsTab';
import SourceContentTab from '../components/Tabs/SourceContent/SourceContentTab';
import text from '../../Common/global/text/text.json';

export const DraftConfigurationScreen: React.FC = (): JSX.Element => (
  <Tabs>
    <Tab label={text.tabSourceContentLabel} tab="source-content">
      <SourceContentTab />
    </Tab>
    <Tab label={text.tabInputsLabel} tab="inputs">
      <InputsTab />
    </Tab>
    <Tab label={text.tabTablesLabel} tab="tables">
      Tables
    </Tab>
    <Tab label={text.tabRulesLabel} tab="rules">
      Rules
    </Tab>
    <Tab label={text.tabOutputsLabel} tab="outputs">
      Outputs
    </Tab>
  </Tabs>
);
