import React from 'react';
import { Table } from '@adsk/alloy-react-table';
import { ParametersTableProps } from './types';
import { TableWrapper } from '../../../../../Common/global/styles';

export const InputsTable: React.FC<ParametersTableProps> = ({
  tableData,
  tableColumns,
  setTableOptions,
  selectedIds,
  handleOnAction,
}): JSX.Element => (
  <TableWrapper>
    <Table
      columns={tableColumns}
      data={tableData as Record<string, any>[]}
      isMultiSelectable
      isResizable
      isSortable
      setTableOptions={setTableOptions}
      selectedRowIds={selectedIds}
      onAction={handleOnAction}
    />
  </TableWrapper>
);

export default InputsTable;
