import React from 'react';
import { SourceContentRightButton, SourceContentHeader, SourceContentTitle } from '../styles';
import text from '../../../../../Common/global/text/text.json';
import { Button } from '@adsk/alloy-react';
interface SourceContentTabHeaderProps {
  enableNextButton: boolean;
  isPreviewStep: boolean;
  setPreviewStep: React.Dispatch<React.SetStateAction<boolean>>;
}

const SourceContentTabHeader: React.FC<SourceContentTabHeaderProps> = ({
  enableNextButton,
  isPreviewStep,
  setPreviewStep,
}): JSX.Element => {
  const handleNextClick = () => {
    // TODO: React router to go to Preview Step
    setPreviewStep(true);
  };

  const handleChangeClick = () => {
    // TODO: React router to go to Preview Step
    setPreviewStep(false);
  };

  return (
    <SourceContentHeader>
      <SourceContentTitle>{text.sourceContentTitle}</SourceContentTitle>
      <div>
        {isPreviewStep ? (
          <SourceContentRightButton
            data-testid="source-content-button-change"
            onClick={handleChangeClick}
            size={Button.SIZES.SMALL}
          >
            {text.buttonChange}
          </SourceContentRightButton>
        ) : (
          <>
            <SourceContentRightButton
              data-testid="source-content-button-next"
              onClick={handleNextClick}
              disabled={!enableNextButton}
              size={Button.SIZES.SMALL}
              variant={Button.VARIANTS.PRIMARY}
            >
              {text.buttonNext}
            </SourceContentRightButton>
          </>
        )}
      </div>
    </SourceContentHeader>
  );
};

export default SourceContentTabHeader;
