import React, { useContext } from 'react';
import NavigationContext from '../../context/Navigation.context';
import { Screens } from '../../context/navigationStore';
import { DraftConfigurationScreen } from '../../screens/DraftConfigurationScreen';
import { DraftTemplatesScreen } from '../../screens/DraftTemplatesScreen';

export const ScreenManager: React.FC = (props) => {
  const { currentScreen } = useContext(NavigationContext);

  if (currentScreen === Screens.DraftTemplates) {
    return <DraftTemplatesScreen {...props} />;
  }
  if (currentScreen === Screens.DraftConfiguration) {
    return <DraftConfigurationScreen {...props} />;
  }
  return <DraftTemplatesScreen {...props} />;
};
