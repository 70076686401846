import { Button } from '@adsk/alloy-react';
import styled from 'styled-components';

export const DraftTableWrapper = styled.div`
  ${({ theme }) => theme.typography.bodyMedium}
`;

export const DraftTemplateTableHeader = styled.div`
  display: flex;
  height: 50px;
  flex-direction: row;
  align-items: center;
`;

export const DraftTemplateTableSummary = styled.h3`
  padding: ${({ theme }) => `${theme.vars.paddingBase}px`};
  ${({ theme }) => theme.typography.heading3}
`;

export const SelectedText = styled.small`
  margin-left: auto;
  padding: ${({ theme }) => `${theme.vars.paddingBase}px`};
  ${({ theme }) => theme.typography.caption}
`;

export const TableHeaderButton = styled(Button)`
  margin: 0px ${({ theme }) => `${theme.vars.marginBase}px`} 0px 0px;
`;
