import { useCallback, useEffect, useState } from 'react';
import { InventorProperties } from '../../../../lib/interfaces/templates';
import { mockInputsData } from './mockData';

export interface UseInputsTabState {
  isEditStep: boolean;
  setIsEditStep: React.Dispatch<React.SetStateAction<boolean>>;
  selectedParameterIds: {};
  setSelectedParameterIds: React.Dispatch<React.SetStateAction<{}>>;
  selectedIPropertyIds: {};
  setSelectedIPropertyIds: React.Dispatch<React.SetStateAction<{}>>;
  selectedInputs: InventorProperties;
  totalSelected: number;
  inventorData: InventorProperties;
}

export const useInputsTab = (): UseInputsTabState => {
  const [isEditStep, setIsEditStep] = useState(false);
  const [selectedParameterIds, setSelectedParameterIds] = useState({});
  const [selectedIPropertyIds, setSelectedIPropertyIds] = useState({});
  const [selectedInputs, setSelectedInputs] = useState<InventorProperties>({
    iProperties: [],
    parameters: [],
  });
  const [totalSelected, setTotalSelected] = useState(0);
  const [inventorData, setInventorData] = useState<InventorProperties>({
    parameters: [],
    iProperties: [],
  });

  const fetchData = useCallback(async () => {
    // TODO: Uncomment the following line to fetch real data
    // const data = await fetchAllInventorData('');
    const data = mockInputsData;
    setInventorData(data);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setTotalSelected(
      Object.keys(selectedParameterIds).length + Object.keys(selectedIPropertyIds).length,
    );
  }, [selectedIPropertyIds, selectedParameterIds]);

  useEffect(() => {
    const selectedProperties = inventorData.iProperties.filter(
      (prop) => prop.id in selectedIPropertyIds,
    );
    const selectedParameters = inventorData.parameters.filter(
      (param) => param.name in selectedParameterIds,
    );
    setSelectedInputs({ parameters: selectedParameters, iProperties: selectedProperties });
  }, [
    inventorData.iProperties,
    inventorData.parameters,
    selectedIPropertyIds,
    selectedParameterIds,
  ]);

  return {
    isEditStep,
    setIsEditStep,
    selectedParameterIds,
    setSelectedParameterIds,
    selectedIPropertyIds,
    setSelectedIPropertyIds,
    selectedInputs,
    totalSelected,
    inventorData,
  };
};
